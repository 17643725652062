<template>
  <div class="page-container">
    <div class="image-column">
      <img src="../assets/background.jpg" alt="Background" class="background-image" />
    </div>
    <div class="form-column">
      <div class="form-content">
        <div class="logo-title-container">
          <img src="../assets/logo.png" alt="Logo" class="logo" />
          <h2 class="header-two">We Are the Dolphins</h2>
        </div>
        <p>Our game is codenamed DogeMaster. Our community will name it later...(nothing to do with Crypto)</p>
        <h1 class="title">Join Our Waitlist</h1>
        <p class="subtitle">Be among the first to play a medevil survial RPG with thousands of NPCs that you can talk to in real time using your voice</p>

        <p class="subtitle"> Start as a peasant, artisan or noble and rise to become the Doge or stick to the shadows and dominate the underworld. Every NPC has an opinion of you so be careful what you say...</p>
        
        <form @submit.prevent="submitForm" class="form">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input v-model="formData.firstName" id="firstName" type="text" required />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input v-model="formData.email" id="email" type="email" required />
          </div>
          <h3>I'm interested in...</h3>
          <div class="checkbox-group">
            <label>
              <input v-model="formData.alphaAccess" type="checkbox" />
              <span>Alpha Access</span>
            </label>
            <label>
              <input v-model="formData.betaAccess" type="checkbox" />
              <span>Beta Access</span>
            </label>
            <label>
              <input v-model="formData.emailUpdates" type="checkbox" />
              <span>Email Updates</span>
            </label>
          </div>
          <button type="submit" class="submit-button">Join Waitlist</button>
        </form>
      </div>
    </div>

    <!-- Modal -->
    <Transition name="fade">
      <div v-if="showModal" class="modal">
        <div class="modal-content" :class="{ 'success': isSuccess, 'error': !isSuccess }">
          <p>{{ modalMessage }}</p>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref } from 'vue'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, addDoc } from 'firebase/firestore'

const firebaseConfig = require(`../../${process.env.VUE_APP_FIREBASE_PATH}`)

const app = initializeApp({
  ...firebaseConfig,
  projectId: 'dogemaster-bf553'
})
const db = getFirestore(app)

export default {
  setup() {
    const formData = ref({
      firstName: '',
      email: '',
      alphaAccess: false,
      betaAccess: false,
      emailUpdates: false,
    })

    const showModal = ref(false)
    const modalMessage = ref('')
    const isSuccess = ref(true)

    const submitForm = async () => {
      if (!formData.value.firstName || !formData.value.email) {
        showMessage('Please enter a valid first name and email.', false)
        return
      }

      if (!validateEmail(formData.value.email)) {
        showMessage('Please enter a valid email.', false)
        return
      }

      try {
        await addDoc(collection(db, 'waitlist'), formData.value)
        showMessage('You have successfully joined the waitlist!', true)
        formData.value = {
          firstName: '',
          email: '',
          alphaAccess: false,
          betaAccess: false,
          emailUpdates: false,
        }
      } catch (error) {
        console.error('Error adding document: ', error)
        showMessage('We\'re receiving too many requests right now. Please try again in a few minutes.', false)
      }
    }

    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    }

    const showMessage = (message, success) => {
      modalMessage.value = message
      isSuccess.value = success
      showModal.value = true
      setTimeout(() => {
        showModal.value = false
      }, 3000) // Hide modal after 3 seconds
    }

    return {
      formData,
      submitForm,
      showModal,
      modalMessage,
      isSuccess,
    }
  },
}
</script>

<style scoped>
.page-container {
  display: flex;
  min-height: 50vh;
  position: relative;
}

.image-column {
  /* flex: 1; */
  overflow: hidden;
  width: 45%;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f8f9fa;
}

.form-content {
  max-width: 400px;
  width: 100%;
}

.logo {
  width: 120px;
  margin-bottom: 20px;
}

.title {
  font-size: 32px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 700;
}

.subtitle {
  font-size: 12px;
  color: #666;
  margin-bottom: 2px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #007BFF;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-group input {
  margin-right: 10px;
}

.submit-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 14px 20px;
  font-size: 18px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

h3 {
  margin-bottom: 10px;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.modal-content {
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 16px;
}

.modal-content.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.modal-content.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .page-container {
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }

  .header-two{
    margin-block-end: 0;
  }

  .image-column {
    height: 22%; /* Reduced from 25vh to 20vh */
    width: 100%;
  }

  .form-column {
    height: 90vh; /* Increased from 75vh to 80vh */
    /* padding: 10px 15px; */
    overflow-y: hidden;
    display: flex;
    align-items: flex-start;
    padding: 30px
  }

  .form-content {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .logo-title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
  }

  .logo {
    width: 40px; /* Further reduced from 50px to 40px */
    margin-right: 10px;
    margin-bottom: 0;
  }

  h2 {
    font-size: 14px; /* Reduced from 16px to 14px */
    margin: 0;
  }

  p {
    font-size: 11px; /* Reduced from 12px to 11px */
    margin-bottom: 3px;
  }

  .title {
    font-size: 18px; /* Reduced from 20px to 18px */
    margin-bottom: 3px;
  }

  .subtitle {
    font-size: 12px; /* Reduced from 14px to 12px */
    margin-bottom: 8px;
  }

  .form {
    gap: 8px;
  }

  .form-group label {
    font-size: 11px; /* Reduced from 12px to 11px */
    margin-bottom: 1px;
  }

  .form-group input {
    padding: 6px;
    font-size: 12px; /* Reduced from 14px to 12px */
  }

  h3 {
    font-size: 13px; /* Reduced from 14px to 13px */
    margin-bottom: 3px;
  }

  .checkbox-group {
    gap: 3px;
  }

  .checkbox-group label {
    font-size: 11px; /* Reduced from 12px to 11px */
  }

  .submit-button {
    padding: 8px 14px;
    font-size: 14px; /* Reduced from 16px to 14px */
    margin-top: 8px;
  }
}
</style>