<template>
  <div id="app">

    <div class="container">
      <router-view ref="view" />
      </div>
  </div>
 
</template>

<script>


export default {
  name: "App",

  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
 
};
</script>
