<template>
  <div class="home-page">

    <HeroSectionDesktop ref="heroSection" />
  </div>
</template>

<script>
// import HeroSection from "@/sections/HeroSection.vue";
import HeroSectionDesktop from "@/sections/HeroSectionDesktop.vue";
// import { mapState } from 'vuex';

export default {
  name: "HomePage",
  components: {
    // HeroSection,
    HeroSectionDesktop,
  },
  data() {
    return {
      isMobile: true,
    };
  },
  
};
</script>

<style>
/* Ensures that all sections use the full available width */
.home-page > * {
  width: 100%;
  box-sizing: border-box; /* Includes padding and border in width calculation */
}
</style>